<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="用户账号">
          <el-input v-model="formInline.userAccount" clearable placeholder="请输入用户账号"></el-input>
        </el-form-item>
        <el-form-item label="商户">
          <el-select v-model="formInline.shopId" filterable clearable placeholder="请选择所属商户">
            <el-option v-for="(item,index) in shopList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="formInline.productName" clearable placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model="formInline.orderNo" clearable placeholder="请输入订单编号"></el-input>
        </el-form-item>
        <el-form-item label="收货人姓名">
          <el-input v-model="formInline.consignee" clearable placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <!-- <el-date-picker
            v-model="formInline.date"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择下单时间">
          </el-date-picker> -->
          <el-date-picker
            v-model="dateTimes"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateTimesChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="formInline.status" clearable placeholder="请选择订单状态">
            <el-option label="待付款" value="0"></el-option>
            <el-option label="已完成" value="1"></el-option>
            <el-option label="已取消" value="2"></el-option>
            <el-option label="待提货" value="3"></el-option>
            <el-option label="已提货" value="4"></el-option>
            <el-option label="已退回" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="exportXcle">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="order-table-expand">
            <div v-for="good in props.row.goods"  :key="good.id">
              <el-form-item label="商品名称">
                <span>{{ good.product_name }}</span>
              </el-form-item>
              <el-form-item label="购买数量">
                <span>{{ good.number }}</span>
              </el-form-item>
              <el-form-item label="商品图片">
                <div class="goodimg">
                  <img v-if="good.pic_url" @click="viewpic(good.pic_url)" :src="good.pic_url" alt="">
                  <span v-else>暂无图片</span>
                </div>
              </el-form-item>
            </div>
            <el-form-item label="支付积分">
              <span>{{ props.row.actual_price }}</span>
            </el-form-item>
            <el-form-item label="支付时间">
              <span>{{ props.row.pay_time }}</span>
            </el-form-item>
            <el-form-item label="收货人">
              <span>{{ props.row.consignee }}</span>
            </el-form-item>
            <el-form-item label="收货电话">
              <span>{{ props.row.mobile }}</span>
            </el-form-item>
            <!-- <el-form-item label="收货地址">
              <span>{{ props.row.address }}</span>
            </el-form-item>
            <el-form-item label="用户订单留言">
              <span>{{ props.row.MESSAGE }}</span>
            </el-form-item> -->
          </el-form>
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod">
      </el-table-column>
      <el-table-column
        label="订单编号"
        prop="no"
        width="200">
      </el-table-column>
      <el-table-column
        label="订单状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0">待付款</span>
          <span v-if="scope.row.status === 1">已完成</span>
          <span v-if="scope.row.status === 2">已取消</span>
          <span v-if="scope.row.status === 3">待提货</span>
          <span v-if="scope.row.status === 4">已提货</span>
          <span v-if="scope.row.status === 5">已退回</span>
        </template>
      </el-table-column>
      <el-table-column
        label="用户账号">
        <template slot-scope="scope">
          <span> {{ scope.row.nickname?scope.row.nickname:'/'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="用户姓名">
        <template slot-scope="scope">
          <span> {{ scope.row.name?scope.row.name:'/'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="下单时间"
        prop="create_time">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="120">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status === 3"
              @click.native.prevent="goodDeliver(scope.row)"
              type="text"
              size="small">
              发货
            </el-button>
            <el-button disabled v-else-if="scope.row.status === 4"
              type="text"
              size="small">
              已发货
            </el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
    </el-table>

    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="imgDialogVisible" title="图片预览" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>
import { orderList, deliver, exportOrder } from '@/api/orderManage'
import { sureMessageBox }　from '@/utils/common'
import { getShopsPage } from '@/api/merchantManage'
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
      formInline: {
        userAccount: '',
        shopId: '',
        productName: '',
        orderNo: '',
        consignee: '',
        date: '',
        status: '',
        startTime: '',
        endTime: '',
      },
      dateTimes: [],
      shopList: [],
      tableData: [],
      tableDataExport: [],
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
      dialogImageUrl: '',
      imgDialogVisible: false
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getShopList();
    this.getOrderList();
  },
  computed:{},
  methods:{
    dateTimesChange(val) {
      if(val) {
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
      } else {
        this.formInline.startTime = null;
        this.formInline.endTime = null;
      }
    },
    //获取订单列表
    getOrderList(){
      orderList({
        userAccount: this.formInline.userAccount,
        shopId: this.formInline.shopId,
        productName: this.formInline.productName,
        orderNo: this.formInline.orderNo,
        consignee: this.formInline.consignee,
        // date: this.formInline.date,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        status: this.formInline.status,
        pageSize: this.pageSize,
        pageNum: this.currentPage
      }).then(res=>{
        // console.log(res)
        if(res.result == 200){
          this.tableData = res.detail.list;
          this.totalRow = res.detail.totalRow;
        }else{
          this.$message.warning(res.description)
        }
      })
    },
    exportXcle() {
      exportOrder({
        userAccount: this.formInline.userAccount,
        shopId: this.formInline.shopId,
        productName: this.formInline.productName,
        orderNo: this.formInline.orderNo,
        consignee: this.formInline.consignee,
        // date: this.formInline.date,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        status: this.formInline.status,
      }).then(res=>{
        if(res.result == 201){ //.result == 200
          this.$message.warning(res.description)
        }else{
          const data = res;
          const a = document.createElement('a');
          const blob = new Blob([data],{type:"application/octet-stream"});
          const blobUrl = window.URL.createObjectURL(blob);
          a.style.display = 'none';
          a.download = '订单管理.xls'; //文件名
          a.href = blobUrl;
          a.click();
        }
      })
    },
    //获取商户列表
    getShopList() {
      getShopsPage({
        name: '',
        phone: '',
        pageSize: 999999,
        pageNum: 1
      }).then(res=>{
        // console.log(res)
        if(res.result == 200){
          this.shopList = res.detail.list;
        }else{
          this.$message.warning(res.description)
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getOrderList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getOrderList();
    },
    //预览大图
    viewpic(url){
      this.imgDialogVisible = true;
      this.dialogImageUrl = url;
    },
    //发货
    goodDeliver(row){
      sureMessageBox('是否确认发货？').then(res=>{
        deliver({
          orderId:row.id 
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功')
            this.getOrderList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    //自定义序号
    indexMethod(index) {
      return (this.currentPage - 1)*this.pageSize + (index+1);
    },
    onSearch() {
      console.log('submit!');
      this.currentPage = 1;
      this.getOrderList();
    }
    
  }
}
</script>

<style lang="scss">
  .searchbox {
    margin: 20px 10px;
  }
  .order-table-expand {
    font-size: 0;
  }
  .order-table-expand label {
    width: 110px;
    color: #99a9bf;
  }
  .order-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  
  .pagebox{
    margin: 20px 0;
    text-align: right;
  }

  .goodimg{
    cursor: pointer;
    img{
      height: 100px;
      width: auto;
    }
  }
</style>